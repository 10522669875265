import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BoxLayer from "../retro/box-layer/box-layer.component";
import "./Paywall.scss";
import { BsPlusLg } from "react-icons/bs";

function Paywall() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const urlparams = new URLSearchParams(window.location.search);
  const interestedInRef = urlparams.get("interested");

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showingPlan, setShowingPlan] = useState("pro");

  useEffect(() => {
    if (interestedInRef) {
      setShowingPlan(interestedInRef);
      setShowPaymentModal(true);
    }
  }, [interestedInRef]);

  return (
    <>
      {showPaymentModal && (
        <div className="paywall_container">
          <BoxLayer setLayer={setShowPaymentModal}>
            <div className="paywall_columns">
              <div className="paywall_columnleft">
                <p>You may upgrade here.</p>
                <button
                  onClick={() => setShowingPlan("pro")}
                  className={
                    showingPlan === "pro" ? "selectedPlanOpt" : "planOption"
                  }
                >
                  Pro Plan
                </button>
                <ul>
                  <li>4 Workspaces</li>
                  <li>24 Projects</li>
                  <li>Unlimited Tasks</li>
                  <li>Unlimited Notes</li>
                  <li>Premium Features</li>
                </ul>
                <button
                  onClick={() => setShowingPlan("business")}
                  className={
                    showingPlan === "business"
                      ? "selectedPlanOpt"
                      : "planOption"
                  }
                >
                  Business Plan
                </button>
                <ul>
                  <li>10 Workspaces</li>
                  <li>Unlimited Projects</li>
                  <li>Unlimited Tasks</li>
                  <li>Unlimited Notes</li>
                  <li>Premium Features</li>
                </ul>
              </div>
              <div className="paywall_columnright">
                {showingPlan === "pro" ? (
                  <iframe
                    src={
                      "https://pay.rev.cat/gcquzNkgQLSKzNAv/" +
                      currentUser.uid +
                      `?email=${currentUser.email}`
                    }
                    // width="100%"
                    height="720px"
                  ></iframe>
                ) : (
                  <iframe
                    src={
                      "https://pay.rev.cat/YVqjZTQgUvKlmErR/" +
                      currentUser.uid +
                      `?email=${currentUser.email}`
                    }
                    width="100%"
                    height="720px"
                  ></iframe>
                )}
              </div>
              <button
                className="client__exit__button"
                onClick={() => setShowPaymentModal(false)}
              >
                <BsPlusLg size="2em" />
              </button>
            </div>
          </BoxLayer>
        </div>
      )}
    </>
  );
}

export default Paywall;
