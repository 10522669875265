import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { HashLink, NavHashLink } from "react-router-hash-link";

import { BsFacebook, BsInstagram, BsLinkedin, BsYoutube } from "react-icons/bs";

import "./Footer.scss";

const Box = styled.div`
  padding: 50px 10% 10px 10%;
  //   position: absolute;
  bottom: 0;
  width: 100%;

  //   background:rgba(197, 197, 197, 0.5);
  background: "white";
  box-shadow: rgb(0 0 0 / 20%) 0px 9px 30px -10px;

  @media (max-width: 1000px) {
    padding: 70px 30px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  //   max-width: 1000px;
  margin: 0 auto;
  /* background: red; */
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  //   padding-right: 60px;
  &:first-child {
    margin-right: 100px;
  }
  //   margin-right: 60px;
  //   align-items: center;
  //   margin-left: 60px;
  @media (max-width: 800px) {
    &:first-child {
      margin-right: 80px;
    }
  }
`;

const Row = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: minmax(300px, 40%) repeat(3, 1fr);
  grid-column-gap: 80px;
  grid-row-gap: 80px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    // grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-column-gap: 80px;
    grid-row-gap: 30px;
  }
`;

const Heading = styled.p`
  font-size: 18px;
  color: black;
  margin-bottom: 24px;
  font-weight: bold;
`;

const Footer = () => {
  const getYear = new Date().getFullYear();

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <Box>
      <Container>
        <Row>
          <Column>
            <div className="logo">
              <HashLink
                to={"https://www.antlogy.com/"}
                smooth
                scroll={scrollWithOffset}
              >
                <img
                  src="/antlogy_logo.png"
                  height="50px"
                  alt=""
                  style={{ objectFit: "cover", cursor: "pointer" }}
                />
              </HashLink>
            </div>
            <p>Start your optimized productivity journey today...</p>
            <div className="footer__socials">
              <a href="https://www.facebook.com/antlogy" target="_blank">
                <BsFacebook />
              </a>
              <a href="https://www.instagram.com/antlogy_com" target="_blank">
                <BsInstagram />
              </a>
              <a
                href="https://www.linkedin.com/company/antlogy"
                target="_blank"
              >
                <BsLinkedin />
              </a>
              <a href="https://www.youtube.com/@antlogy" target="_blank">
                <BsYoutube />
              </a>
            </div>
          </Column>
          <Column>
            <HashLink
              to={"https://www.antlogy.com/"}
              smooth
              scroll={scrollWithOffset}
            >
              <Heading>Antlogy</Heading>
            </HashLink>
            <HashLink
              to={"https://www.antlogy.com/#features"}
              smooth
              scroll={scrollWithOffset}
              className="footer__link"
            >
              Features
            </HashLink>
            <HashLink
              to={"https://www.antlogy.com/#collaboration"}
              smooth
              scroll={scrollWithOffset}
              className="footer__link"
            >
              For Teams
            </HashLink>
            <HashLink
              to={"https://www.antlogy.com/#pricing"}
              smooth
              scroll={scrollWithOffset}
              className="footer__link"
            >
              Pricing
            </HashLink>
            <HashLink
              to={"https://www.antlogy.com/#consultancy"}
              smooth
              scroll={scrollWithOffset}
              className="footer__link"
            >
              Consult
            </HashLink>
          </Column>
          <Column>
            <Heading>Resources</Heading>
            <Link to="https://www.antlogy.com/apps" className="footer__link">
              Apps
            </Link>
            <Link to="https://www.antlogy.com/blog" className="footer__link">
              Blog
            </Link>
            <HashLink
              to={"https://www.antlogy.com/faqs"}
              smooth
              scroll={scrollWithOffset}
              className="footer__link"
            >
              FAQs
            </HashLink>
          </Column>
          <Column>
            <Heading>Support</Heading>
            <Link
              to="https://www.antlogy.com/termsandconditions"
              className="footer__link"
            >
              Terms & Conditions
            </Link>
            <Link
              to="https://www.antlogy.com/privacypolicy"
              className="footer__link"
            >
              Privacy Policy
            </Link>
            <Link
              to="https://www.antlogy.com/contact-us"
              className="footer__link"
            >
              Contact us
            </Link>
          </Column>
        </Row>
      </Container>
      <div className="footer__bottom">
        <div>© {getYear}, Antlogy LLC. All rights reserved.</div>{" "}
        <HashLink
          to={"/signin/#"}
          smooth
          scroll={scrollWithOffset}
          className="footer__link"
        >
          Back to top
        </HashLink>
      </div>
    </Box>
  );
};
export default Footer;
